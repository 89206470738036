import React, { useState } from "react";
import "../styles/FAQ.scss";

import arrowIcon from "../assets/ackapa.png";

const FAQ = ({ faqData }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="faq-container">
      <h1>Sıkça Sorulan Sorular</h1>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? "active" : ""}`}
          >
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <p>{item.question}</p>
              <span
                className={`arrow ${activeIndex === index ? "up" : "down"}`}
              >
                <img src={arrowIcon} alt="Up&Down" />
              </span>
            </div>
            <div className="faq-answer">
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
