import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";

import "../styles/Hero.scss";

//background imgs
import bg1 from "../assets/hero-1.jpg";
import bg2 from "../assets/hero-2.jpg";
import bg3 from "../assets/hero-3.jpg";

//prev and next imgs
import nextArrow from "../assets/nextArrow.png";
import prevArrow from "../assets/prevArrow.png";
import playIcon from "../assets/playIcon.png";

const slides = [
  {
    title: "Güvenliğiniz İçin İnovasyon",
    subtitle:
      "Kaza Riskini ve Fren Mesafesini Azaltın, Yol Güvenliğini Artırın.",
    image: bg1,
  },
  {
    title: "Yakıt Verimliliğini Artırın",
    subtitle:
      "Yakıt tüketimini azaltın, lastik ömrünü uzatın, zamanında teslimat.",
    image: bg2,
  },
  {
    title: "Tasarrufu Maksimize Edin",
    subtitle: "Hurda lastik ve maliyetleri düşürün, tasarrufu artırın.",
    image: bg3,
  },
];

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 5000); // Change slide every 3 seconds

    return () => clearInterval(slideInterval); // Clean up interval on component unmount
  }, [currentSlide]);

  const { title, subtitle, image } = slides[currentSlide];

  return (
    <div className="hero">
      <div className="hero_container">
        <div className="hero_slide">
          <img src={image} alt={title} />
          <div className="hero_content">
            <div className="hero_buttons">
              <button onClick={prevSlide}>
                <img src={prevArrow} alt="" />
              </button>
              <button onClick={nextSlide}>
                <img src={nextArrow} alt="" />
              </button>
            </div>
            <div className="hero_main">
              <h1>{title}</h1>
              <p>{subtitle}</p>
              <div className="hero_plays">
                <Link to="/about" className="btn">
                  Hakkımızda
                </Link>
                <button onClick={openModal} className="play_button">
                  <img src={playIcon} alt="Play" />
                  <span className="play_text">tanıtımızı izleyin</span>
                </button>
              </div>
            </div>
            <ReactModal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="YouTube Video"
              className="youtube-modal"
              overlayClassName="modal-overlay"
            >
              <div className="modal-content">
                <button onClick={closeModal} className="close-modal">
                  X
                </button>
                <iframe
                  // width="560"
                  // height="315"
                  src="https://www.youtube.com/embed/6rPR80KMa8c?si=SNb6TgF8Nj0lg6yk"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </ReactModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
