import React from "react";
import { Link } from "react-router-dom";
import "../styles/About.scss"; //styles

//images
import MainImage from "../assets/aboutMain.png";
import SideImage from "../assets/aboutSide.png";
import Check from "../assets/check-green.png";

const About = () => {
  return (
    <div className="about">
      <div className="about_container">
        <div className="about_images">
          <img src={MainImage} alt="Main Activity" className="main-image" />
          <img src={SideImage} alt="Side Activity" className="side-image" />
        </div>
        <div className="about_text">
          <h2>Neden Airguard?</h2>
          <p>
            Verimliliğinizi artırarak lastik ömrünü uzatıyor ve yakıt tüketimini
            düşürüyoruz. Emniyet konusunda, kaza riskini azaltıp yol
            güvenliğinizi sağlıyoruz. Ayrıca, hurda lastik sayısını ve
            operasyonel maliyetlerinizi düşürerek size önemli ölçüde kazanç
            sağlıyoruz.
          </p>
          <ul>
            <li>
              <img src={Check} alt="Verimlilik " /> VERİMLİLİK
            </li>
            <li>
              <img src={Check} alt="Emniyet" /> EMNİYET
            </li>
            <li>
              <img src={Check} alt="kazanc" /> KAZANÇ
            </li>
          </ul>
          <Link to="/calculator" className="button">
            <button className="calculate-button">Kazancı Hesapla</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
