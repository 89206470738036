import React from "react";

import "../styles/Popup.scss";

const Popup = ({ message, type }) => {
  return (
    <div className={`popup ${type} show`}>
      <i
        className={`popup_icon ${
          type === "success" ? "fa fa-check" : "fa fa-times"
        }`}
      ></i>
      <span className="popup_message">{message}</span>
    </div>
  );
};

export default Popup;
