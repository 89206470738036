import React from "react";
import { Link } from "react-router-dom";

import "../styles/Ctn.scss";

const CTA = () => {
  return (
    <div className="ctn">
      <div className="overlay">
        <div className="button-container">
          <div className="button-content">
            <h2>Hizmetimize mi İhtiyacınız Var?</h2>
            <button className="cta-button">
              <Link to="/contact">İletişime Geç</Link>
            </button>
          </div>
          <div className="button-content">
            <h2>Kazancınızı Hesapladınız mı?</h2>
            <button className="cta-button">
              <Link to="/calculator">Hemen Hesapla</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
