import React from "react";
import { Swiper as SwiperCore, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../styles/Swiper.scss";

const details = [
  {
    id: 1,
    title: "Uzmanlığına Güvendiğiniz Ekip",
    description: "Profesyonel ve Güvenilir ",
  },
  {
    id: 2,
    title: "Uzmanlığına Güvendiğiniz Ekip",
    description: "Hızlı ve Etkili Çözümler Sunar",
  },
  {
    id: 3,
    title: "Uzmanlığına Güvendiğiniz Ekip",
    description: "Birçok Büyük Firmanın Tercihi",
  },
  {
    id: 4,
    title: "Uzmanlığına Güvendiğiniz Ekip",
    description: "Kurulum ve Destek Uzmanı",
  },
];

const SwiperSlider = () => {
  return (
    <div className="changeback">
      <div className="swiper">
        <SwiperCore
          modules={[Autoplay]}
          spaceBetween={40}
          slidesPerView={3.7} // Slightly less than 4 to avoid perfect fit issue
          allowSlidePrev={true} // Allow slide to previous
          loop={true} // Infinite loop
          mousewheel={true} // Allow slide by mouse wheel
          grabCursor={true} // Allow dragging by mouse        loop={true}
          autoplay={{ delay: 3000 }}
          shortSwipes={true} // Allow short swipe
          longSwipes={true} // Allow long swipe
          slidesOffsetAfter={200}
          breakpoints={{
            1500: {
              slidesPerView: 3.4,
              spaceBetween: 40,
              slidesOffsetAfter: 400,
            },

            //when window width is >= 1280px (desktop)
            1280: {
              slidesPerView: 3.5,
              spaceBetween: 40,
              slidesOffsetAfter: 200,
            },

            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            // When window width is >= 768px (tablet)
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            // When window width is < 768px (mobile)

            480: {
              slidesPerView: 2,
              spaceBetween: 20,
            },

            280: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          {details.map((detail) => (
            <SwiperSlide className="swiper_container" key={detail.id}>
              <div className="swiper_slide">
                <div className="swiper_text">
                  <span>{detail.title}</span>
                  <h1>{detail.description}</h1>
                </div>
                <div className="swiper_num">
                  <span>0{detail.id}</span>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </SwiperCore>
      </div>
    </div>
  );
};

export default SwiperSlider;
