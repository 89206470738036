import React, { useEffect, useState } from "react";

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if consent or rejection cookie exists
    const consentCookie = document.cookie.includes("consent=true");
    const rejectCookie = document.cookie.includes("consent=false");

    if (!consentCookie && !rejectCookie) {
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    document.cookie = "consent=true; path=/; max-age=31536000;"; // 1 year
    setShowBanner(false);
    // Initialize third-party services here if needed
  };

  const rejectCookies = () => {
    document.cookie = "consent=false; path=/; max-age=86400;"; // 1 year
    setShowBanner(false);
    // No cookies will be set, and no further prompts will be shown
  };

  if (!showBanner) return null;

  return (
    <div id="cookie-consent-banner" style={styles.banner}>
      <p>
        Web sitemiz, kullanıcı deneyimini geliştirmek için çerezler
        kullanmaktadır. Çerezleri kabul ediyor musunuz?
      </p>
      <div style={styles.buttonContainer}>
        <button style={styles.acceptButton} onClick={acceptCookies}>
          Kabul Et
        </button>
        <button style={styles.rejectButton} onClick={rejectCookies}>
          Reddet
        </button>
      </div>
    </div>
  );
};

// Inline styles for the banner and buttons
const styles = {
  banner: {
    backgroundColor: "#f8d7da",
    padding: "10px",
    position: "fixed",
    bottom: "0",
    width: "100%",
    zIndex: "1000",
    textAlign: "center",
  },
  buttonContainer: {
    marginTop: "10px",
  },
  acceptButton: {
    backgroundColor: "#28a745", // Green for accept
    color: "#ffffff",
    border: "none",
    padding: "10px 20px",
    marginRight: "10px",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "16px",
  },
  rejectButton: {
    backgroundColor: "#dc3545", // Red for reject
    color: "#ffffff",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "16px",
  },
};

export default CookieConsent;
