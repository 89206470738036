import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/ProductSlider.scss";
import products from "../products";

//next and prev arrows
import nextArrow from "../assets/right.png";
import prevArrow from "../assets/left.png";

export const ProductCard = ({ product }) => {
  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + "...";
  };

  return (
    <div className="product_card">
      <img
        src={product.image}
        alt={product.name}
        className="product_card_img"
      />
      <h3 className="product_card_name">{product.title}</h3>
      <p className="product_card_desc">
        {truncateText(product.description, 60)}
      </p>
      <Link to={`/product/${product.name}`} className="product_card_link">
        Özellikleri İncele...
      </Link>
    </div>
  );
};

const LeftArrow = () => <img src={prevArrow} alt="onceki" />;

const RightArrow = () => <img src={nextArrow} alt="sonraki" />;

const ProductCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [visibleProducts, setVisibleProducts] = useState(4);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : products.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < products.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const currentX = e.touches[0].clientX;
    const diff = startX - currentX;
    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        handleNext();
      } else {
        handlePrev();
      }
      setIsDragging(false);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1280) {
        setVisibleProducts(4);
      } else if (window.innerWidth >= 992) {
        setVisibleProducts(3);
      } else if (window.innerWidth >= 768) {
        setVisibleProducts(2);
      } else {
        setVisibleProducts(1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getVisibleProducts = () => {
    const productsToShow = products.slice(
      currentIndex,
      currentIndex + visibleProducts
    );
    if (productsToShow.length < visibleProducts) {
      // If we are near the end of the product array and there's a gap, add products from the start
      const remaining = visibleProducts - productsToShow.length;
      return [...productsToShow, ...products.slice(0, remaining)];
    }
    return productsToShow;
  };

  return (
    <div className="products">
      <h2 className="products_heading">Ürünlerimizi İnceleyin</h2>
      <div className="products_container">
        <button onClick={handlePrev} className="">
          <LeftArrow />
        </button>
        <div
          ref={carouselRef}
          className="products_list"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {getVisibleProducts().map((product, index) => (
            <div
              key={`${product.id}-${index}`}
              className="transition-all duration-300 product"
            >
              <ProductCard product={product} />
            </div>
          ))}
        </div>
        <button onClick={handleNext} className="">
          <RightArrow />
        </button>
      </div>
    </div>
  );
};

export default ProductCarousel;
