import React from "react";
import { Helmet } from "react-helmet";
import products from "../products";
import { ProductCard } from "../components/ProductSlider";

import "../styles/Products.scss";
import { Link } from "react-router-dom";

const Products = () => {
  return (
    <div className="products_page">
      <Helmet>
        <title>Ürünlerimiz - Airguard</title>
        <meta
          name="description"
          content="Airguard'ın geniş ürün yelpazesiyle, lastik güvenliği ve performansı için gelişmiş sensörler, monitörler ve sinyal güçlendiriciler sunuyoruz."
        />
        <meta
          name="keywords"
          content="Airguard ürünleri, lastik güvenliği, araç sensörleri, lastik basınç izleme, araç güvenlik sistemi, yakıt tasarrufu, operasyonel maliyet azaltma, monitor, sinyal güçlendirici"
        />
        <meta name="author" content="Airguard" />
      </Helmet>
      <h1>Ürünlerimiz</h1>

      <div className="products_page_container">
        {products.map((product) => (
          <Link
            to={`../product/${product.name}`}
            className="products_page_item"
          >
            {" "}
            <ProductCard key={product.id} product={product} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Products;
