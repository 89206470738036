import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";

import "../styles/Users.scss";
import YtCarousel from "./YtCarousel";
import Logos from "./Logos";
import { importImages } from "../utils/importImages";

// Import all images from the folder
const images = importImages(
  require.context("../assets/partners", false, /\.(png|jpe?g|svg)$/)
);

const youtubeVideos = [
  "https://www.youtube.com/embed/akUbBZd3GN0?si=u595jghc-3i5qu19",
  "https://www.youtube.com/embed/_1W8u1E3wDQ?si=rzSMbfh7oGI2_SWM",
  "https://www.youtube.com/embed/9yuIZSev2pY?si=YRs27-Vm_OwCu29S",
  "https://www.youtube.com/embed/ABagV3OrKm8?si=drQ604-mJxaASX61",
];

const Users = () => {
  const imageArray = Object.values(images);
  return (
    <div className="users">
      <YtCarousel videos={youtubeVideos} />
      <Logos images={imageArray} />
    </div>
  );
};

export default Users;
