import React, { useState } from "react";
import newRequest from "../utils/newRequest";

import Popup from "./Popup";

const sampleData = {
  email: "rassamrl@gmail.com",
  milage: 15000,
  avgFuel: 8,
  fuelPrice: 20,
  numberOfTyres: 4,
  avgTyreCost: 300,
  avgTyreLife: 50000,
  tyreMaintenance: 1,
  tyreMaintenanceCost: 50,
  roadAsstCost: 200,
  tyreAsstCost: 150,
  downTimeCost: 100,
  roadAsstNumber: 2,
  tpmsPrice: 1200,
  installationCost: 300,
  numOfVehicle: 10,
};

const details = {
  numOfVehicle: {
    label: "Araç Sayısı",
    placeholder: "Kaç adet araç var? Örneğin: 5",
    min: 1,
    max: 100,
    default: sampleData.numOfVehicle, // 10
  },
  milage: {
    label: "Yıllık Kilometre",
    placeholder: "Yılda ortalama kaç kilometre yapılıyor? Örneğin: 50000",
    min: 5000,
    max: 200000,
    default: sampleData.milage, // 15000
  },
  avgFuel: {
    label: "Ortalama Yakıt Tüketimi (L/100km)",
    placeholder: "Araç başına 100 km'de tüketilen yakıt miktarı. Örneğin: 8",
    min: 3,
    max: 25,
    default: sampleData.avgFuel, // 8
  },
  fuelPrice: {
    label: "Yakıt Fiyatı (TL/Litre)",
    placeholder: "Yakıtın litre fiyatı. Örneğin: 25",
    min: 5,
    max: 50,
    default: sampleData.fuelPrice, // 20
  },
  numberOfTyres: {
    label: "Lastik Sayısı",
    placeholder: "Her araç için kaç lastik kullanılıyor? Örneğin: 4",
    min: 4,
    max: 18, // For larger vehicles like trucks
    default: sampleData.numberOfTyres, // 4
  },
  avgTyreCost: {
    label: "Ortalama Lastik Maliyeti (TL)",
    placeholder: "Bir lastiğin maliyeti. Örneğin: 1000",
    min: 200,
    max: 10000,
    default: sampleData.avgTyreCost, // 300
  },
  avgTyreLife: {
    label: "Ortalama Lastik Ömrü (km)",
    placeholder: "Bir lastiğin dayanabileceği toplam kilometre. Örneğin: 40000",
    min: 10000,
    max: 100000,
    default: sampleData.avgTyreLife, // 50000
  },
  tyreMaintenance: {
    label: "Yıllık Lastik Bakımı Sayısı",
    placeholder: "Yılda kaç defa lastik bakımı yapılıyor? Örneğin: 2",
    min: 0,
    max: 15, // Assuming monthly check-ups are a possibility
    default: sampleData.tyreMaintenance, // 1
  },
  tyreMaintenanceCost: {
    label: "Bir Bakım Maliyeti (TL)",
    placeholder: "Her bir lastik bakımının maliyeti. Örneğin: 200",
    min: 50,
    max: 1000,
    default: sampleData.tyreMaintenanceCost, // 50
  },
  roadAsstCost: {
    label: "Yol Yardımı Maliyeti (TL)",
    placeholder: "Bir araç için yol yardımı maliyeti. Örneğin: 500",
    min: 100,
    max: 5000,
    default: sampleData.roadAsstCost, // 200
  },
  tyreAsstCost: {
    label: "Lastik Değişim Yardımı Maliyeti (TL)",
    placeholder:
      "Lastik değiştirmek için gerekli yardım maliyeti. Örneğin: 150",
    min: 50,
    max: 1000,
    default: sampleData.tyreAsstCost, // 150
  },
  downTimeCost: {
    label: "Yol Dışı Kalma Maliyeti (TL/saat)",
    placeholder: "Aracın çalışmadığı her saat için maliyet. Örneğin: 300",
    min: 50,
    max: 5000,
    default: sampleData.downTimeCost, // 100
  },
  roadAsstNumber: {
    label: "Yol Yardımı Gerektiren Olay Sayısı",
    placeholder:
      "Yılda kaç defa yol yardımı gerektiren olay yaşandı? Örneğin: 3",
    min: 0,
    max: 20,
    default: sampleData.roadAsstNumber, // 2
  },
  tpmsPrice: {
    label: "Lastik Basınç Ölçüm Sistemi Fiyatı (TL)",
    placeholder: "TPMS cihazının fiyatı. Örneğin: 2500",
    min: 500,
    max: 15000,
    default: sampleData.tpmsPrice, // 1200
  },
  installationCost: {
    label: "Montaj Maliyeti (TL)",
    placeholder: "TPMS montaj maliyeti. Örneğin: 300",
    min: 100,
    max: 5000,
    default: sampleData.installationCost, // 300
  },
};

const Calculator = () => {
  const [formData, setFormData] = useState({
    numOfVehicle: "",
    milage: "",
    avgFuel: "",
    fuelPrice: "",
    numberOfTyres: "",
    avgTyreCost: "",
    avgTyreLife: "",
    tyreMaintenance: "",
    tyreMaintenanceCost: "",
    roadAsstCost: "",
    tyreAsstCost: "",
    downTimeCost: "",
    roadAsstNumber: "",
    tpmsPrice: "",
    installationCost: "",
    email: "",
  });
  const [results, setResults] = useState(undefined);
  const [popup, setPopup] = useState({ message: "", type: "" });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === "email"
          ? e.target.value
          : parseInt(e.target.value, 10),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await newRequest.post("/calculate", formData);

      setResults(response.data.data);
      setPopup({ message: "Hesaplama başarıyla tamamlandı!", type: "success" });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        // Extract field-specific error messages
        const errorMessages = Object.values(error.response.data.errors).join(
          ", "
        );
        // Display the error messages in the popup
        setPopup({ message: errorMessages, type: "error" });
      } else {
        // Handle unexpected errors (network issues, server errors, etc.)
        setPopup({
          message: "Bir hata oluştu, lütfen tekrar deneyin.",
          type: "error",
        });
      }
      console.error("Bir hata oluştu:", error);
    }
  };

  const handleReset = () => {
    setResults(undefined);
    setFormData({
      numOfVehicle: "",
      milage: "",
      avgFuel: "",
      fuelPrice: "",
      numberOfTyres: "",
      avgTyreCost: "",
      avgTyreLife: "",
      tyreMaintenance: "",
      tyreMaintenanceCost: "",
      roadAsstCost: "",
      tyreAsstCost: "",
      downTimeCost: "",
      roadAsstNumber: "",
      tpmsPrice: "",
      installationCost: "",
      email: "",
    });
  };

  return (
    <div className="form-container">
      {!results ? (
        <>
          <h3 className="form-title">Kazanç Hesaplama Makinesi</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="form-label" htmlFor="email">
                E-posta:
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Örnek@mail.com"
                className="form-input"
                required
              />
            </div>
            {Object.keys(details).map((key) => (
              <div key={key} className="form-group">
                <div className="form-group-top">
                  <label className="form-label" htmlFor={key}>
                    {details[key].label}:
                  </label>
                  <input
                    type="number"
                    min={details[key].min}
                    name={key}
                    onChange={handleChange}
                    value={formData[key]}
                    className="current-val"
                  />
                  {/* <span className="current-val">
                    {!formData[key] ? details[key].min : formData[key]}
                  </span> */}
                </div>

                <div className="range-container">
                  {/* Min value label */}
                  <span className="range-min">Min: {details[key].min}</span>

                  {/* Range input */}
                  <input
                    type="range"
                    min={details[key].min}
                    max={details[key].max}
                    name={key}
                    onChange={handleChange}
                    // defaultValue={details[key].min}
                    value={formData[key]}
                    className="form-input custom-range"
                  />
                  <span className="range-max">Max: {details[key].max}</span>
                </div>
              </div>
            ))}
            <button type="submit" className="submit-btn">
              Hesapla
            </button>
          </form>
          {popup.message && <Popup message={popup.message} type={popup.type} />}
        </>
      ) : (
        <div className="results-container">
          <h3 className="results-title">Sonuçlarınız</h3>

          <p className="mb-2 text-gray-700">
            <strong>10% Ek Yakıt Tasarrufu:</strong> {results.monthlySavings10}{" "}
            TL (Aylık), {results.extraFuelCost10} TL (Araç Başına)
            <br />
            Yatırımınız sayesinde yakıt tüketiminde %10 tasarruf sağlayarak
            bütçenize katkıda bulunabilirsiniz.
          </p>
          <p className="mb-2 text-gray-700">
            <strong>20% Ek Yakıt Tasarrufu:</strong> {results.monthlySavings20}{" "}
            TL (Aylık), {results.vehicleMonthlySavings20} TL (Araç Başına)
            <br />
            Yakıt tasarrufundaki %20'lik iyileşme, uzun vadede önemli ölçüde
            maliyet avantajı sağlar.
          </p>
          <p className="mb-2 text-gray-700">
            <strong>30% Ek Yakıt Tasarrufu:</strong> {results.monthlySavings30}{" "}
            TL (Aylık), {results.vehicleMonthlySavings30} TL (Araç Başına)
            <br />
            %30'luk bir tasarruf oranı, yakıt maliyetlerinizi ciddi şekilde
            azaltır ve bütçenizi optimize eder.
          </p>
          <p className="mb-2 text-gray-700">
            <strong>TPMS sisteminizin geri dönüş süresi:</strong>
            <br />
            %10 Tasarruf: {results.roi10} ay
            <br />
            %20 Tasarruf: {results.roi20} ay
            <br />
            %30 Tasarruf: {results.roi30} ay
            <br />
            Ne kadar hızlı kâr elde edeceğinizi gösterir.
          </p>
          <p className="mb-2 text-gray-700">
            <strong>Toplam Tasarruf:</strong>
            <br />
            %10 Tasarruf: {results.totalSavings10} TL
            <br />
            %20 Tasarruf: {results.totalSavings20} TL
            <br />
            %30 Tasarruf: {results.totalSavings30} TL
            <br />
            Yıllık toplam tasarruflar, yatırımlarınızın getirilerini net bir
            şekilde gösterir.
          </p>
          <p className="mb-2 text-gray-700">
            <strong>Ekstra Lastik Maliyeti:</strong>
            <br />
            %10 Ekstra Lastik Maliyeti: {results.extraTireCost10} TL
            <br />
            %20 Ekstra Lastik Maliyeti: {results.extraTireCost20} TL
            <br />
            %30 Ekstra Lastik Maliyeti: {results.extraTireCost30} TL
            <br />
            Lastik maliyetlerindeki değişiklikler, bakım bütçenizi etkiler.
          </p>
          <p className="mb-2 text-gray-700">
            <strong>Lastik Bakım Kontrol Maliyeti:</strong>{" "}
            {results.tyreMaintenanceCheckCost} TL
            <br />
            Düzenli bakım kontrolleri, lastiklerinizin ömrünü uzatır ve
            maliyetlerinizi düşürür.
          </p>
          <p className="mb-2 text-gray-700">
            <strong>Toplam Yol Dışı Kalma Maliyeti:</strong>{" "}
            {results.overallDownTimeCost} TL
            <br />
            Araçlarınızın yol dışı kalma süreleri, operasyonel maliyetlerinizi
            etkiler ve bütçenizi planlamanızda önemli rol oynar.
          </p>
          <button onClick={handleReset} className="reset-btn">
            Tekrar Hesapla
          </button>
        </div>
      )}
    </div>
  );
};

export default Calculator;
