import React from "react";
import { Helmet } from 'react-helmet';
import Hero from "../components/Hero";

import "../styles/HomePage.scss";
import About from "../components/About";
import SwiperSlider from "../components/Swiper";
import ContactForm from "../components/ContactForm";
import ProductSlider from "../components/ProductSlider";
import ReviewSwiper from "../components/ReviewSwiper";
import Users from "../components/Users";
import CTA from "../components/CTA";

const HomePage = () => {
  return (
    <div className="home-page">
       <Helmet>
        <title>Airguard - Araç Güvenliği ve Lastik Yönetim Sistemleri</title>
        <meta 
          name="description" 
          content="Airguard ile lastik ömrünü uzatın, yakıt tüketimini azaltın, yol güvenliğinizi artırın ve operasyonel maliyetleri düşürün. Gelişmiş sensör teknolojileri ve yenilikçi çözümlerle aracınızı koruyun." 
        />
        <meta 
          name="keywords" 
          content="Airguard, araç güvenliği, lastik yönetim sistemi, yakıt tüketimini azaltma, lastik ömrü uzatma, operasyonel maliyet azaltma, sensör teknolojisi, inovasyon" 
        />
        <meta name="author" content="Airguard" />
      </Helmet>
      <Hero />
      <SwiperSlider />
      <ContactForm />
      <About />
      <ProductSlider />
      <ReviewSwiper />
      <Users />
      <CTA />
    </div>
  );
};

export default HomePage;
