import React from "react";
import { Helmet } from "react-helmet";
// import styled from "styled-components";
import aboutImage from "../assets/AboutUs.jpg";

import "../styles/AboutUs.scss";

// Styled components for responsiveness
// const AboutWrapper = styled.div`
//   padding: 7rem 2rem 2rem 2rem;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   max-width: 1200px;
//   margin: 0 auto;

//   @media (max-width: 768px) {
//     padding: 7rem 1rem 1rem 1rem;
//   }
// `;

// const Title = styled.h1`
//   font-size: 2.5rem;
//   color: #333;
//   margin-bottom: 1rem;
//   text-align: center;
//   @media (max-width: 768px) {
//     font-size: 2rem;
//   }
// `;

// const SubTitle = styled.h2`
//   font-size: 1.8rem;
//   color: #666;
//   margin-bottom: 1rem;
//   text-align: center;

//   @media (max-width: 768px) {
//     font-size: 1.5rem;
//   }
// `;

// const Paragraph = styled.p`
//   font-size: 1rem;
//   color: #555;
//   line-height: 1.6;
//   text-align: justify;
//   margin-bottom: 1rem;

//   @media (max-width: 768px) {
//     font-size: 0.9rem;
//   }
// `;

// const ContactLink = styled.a`
//   font-size: 1.1rem;
//   color: #007bff;
//   text-decoration: none;
//   margin-top: 1rem;

//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const Image = styled.img`
//   width: 60%;

//   border-radius: 10px;
//   margin-bottom: 1rem;
//   @media (max-width: 768px) {
//     widht: 100%;
//   }
// `;

// const ImageCaption = styled.p`
//   font-size: 0.9rem;
//   color: #666;
//   text-align: center;
// `;

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>Airguard - Hakkımızda | Güvenli Sürüş Teknolojileri</title>
        <meta
          name="description"
          content="Airguard, araç güvenliği ve performansını en üst seviyeye çıkarmak için gelişmiş lastik yönetim sistemleri ve yenilikçi sensör teknolojileri sunmaktadır."
        />
        <meta
          name="keywords"
          content="Airguard, lastik basınç yönetimi, güvenli sürüş teknolojileri, araç güvenliği, uzaktan bilgilendirme, lastik sensör teknolojileri"
        />
      </Helmet>
      {/* <AboutWrapper>
        <Title>Hakkımızda</Title>

        <Image src={aboutImage} alt="Hakkımızda" />
        <ImageCaption>
          Airguard, güvenli sürüş için ileri teknoloji çözümleri sunar.
        </ImageCaption>
        <br />

        <SubTitle>Güvenli Sürüş İçin İleri Teknoloji Çözümleri</SubTitle>
        <Paragraph>
          Airguard olarak, araç güvenliği ve performansını en üst seviyeye
          çıkarmak için gelişmiş lastik yönetim sistemleri ve yenilikçi sensör
          teknolojileri geliştiriyoruz. Misyonumuz, sürücülere ve taşımacılık
          sektörüne, lastiklerin ömrünü uzatarak, yakıt tüketimini azaltarak ve
          operasyonel maliyetleri düşürerek güvenli ve verimli bir sürüş
          deneyimi sunmaktır.
        </Paragraph>
        <Paragraph>
          Airguard ürünleri, lastik basıncı ve sıcaklık bilgilerini gerçek
          zamanlı olarak izleyen ve anında uyarı veren sistemlerden, kablosuz
          park sensörleri ve sinyal güçlendiricilere kadar geniş bir yelpazeye
          sahiptir. Ürünlerimiz, güvenilir veri transferi ve kolay kurulum
          özellikleri ile öne çıkar, böylece her türlü aracın güvenlik ve
          performans standartlarını artırır.
        </Paragraph>
        <Paragraph>
          Sunduğumuz çözümler arasında uzaktan kontrol sistemleri, treyler
          güvenlik çözümleri ve online raporlama araçları yer almaktadır. Tüm bu
          sistemler, araçların kazaları önlemesini, fren mesafelerini
          azaltmasını ve daha güvenli bir yolculuk sunmasını sağlar.
        </Paragraph>
        <Paragraph>
          Güvenlik standartlarını yükseltme ve yolculuklarınızı daha güvenli
          hale getirme misyonumuz doğrultusunda, web sitemiz üzerinden
          sunduğumuz TPMS Amortisman Hesaplama Aracı ve teklif formu ile
          araçlarınızın lastik yönetimini optimize etmenize olanak tanıyoruz.
        </Paragraph>
        <ContactLink href="/contact">
          Güvenli sürüş için bizimle iletişime geçin
        </ContactLink>
      </AboutWrapper> */}

      <div class="about-wrapper">
        <h1>Hakkımızda</h1>
        <img src={aboutImage} alt="Hakkımızda" class="about-image" />

        <h2>Güvenli Sürüş İçin İleri Teknoloji Çözümleri</h2>
        <h3>Misyonumuz</h3>
        <p>
          Airguard olarak, araç güvenliği ve performansını en üst seviyeye
          çıkarmak için gelişmiş lastik yönetim sistemleri ve yenilikçi sensör
          teknolojileri geliştiriyoruz. Misyonumuz, sürücülere ve taşımacılık
          sektörüne, lastiklerin ömrünü uzatarak, yakıt tüketimini azaltarak ve
          operasyonel maliyetleri düşürerek güvenli ve verimli bir sürüş
          deneyimi sunmaktır.
        </p>

        <h3>Ürün Yelpazemiz</h3>
        <p>
          Airguard ürünleri, lastik basıncı ve sıcaklık bilgilerini gerçek
          zamanlı olarak izleyen ve anında uyarı veren sistemlerden, kablosuz
          park sensörleri ve sinyal güçlendiricilere kadar geniş bir yelpazeye
          sahiptir. Ürünlerimiz, güvenilir veri transferi ve kolay kurulum
          özellikleri ile öne çıkar, böylece her türlü aracın güvenlik ve
          performans standartlarını artırır.
        </p>

        <h3>Çözümlerimiz</h3>
        <p>
          Sunduğumuz çözümler arasında uzaktan kontrol sistemleri, treyler
          güvenlik çözümleri ve online raporlama araçları yer almaktadır. Tüm bu
          sistemler, araçların kazaları önlemesini, fren mesafelerini
          azaltmasını ve daha güvenli bir yolculuk sunmasını sağlar.
        </p>

        <h3>TPMS Amortisman Hesaplama Aracı</h3>
        <p>
          Güvenlik standartlarını yükseltme ve yolculuklarınızı daha güvenli
          hale getirme misyonumuz doğrultusunda, web sitemiz üzerinden
          sunduğumuz TPMS Amortisman Hesaplama Aracı ve teklif formu ile
          araçlarınızın lastik yönetimini optimize etmenize olanak tanıyoruz.
        </p>

        <a href="/contact" class="contact-link">
          Güvenli sürüş için bizimle iletişime geçin
        </a>
      </div>
    </>
  );
};

export default AboutUs;
