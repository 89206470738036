import React from "react";

import "../styles/Footer.scss";
import logoImage from "../assets/logo-Desktop.png"; // Update this path

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-logo">
        <img src={logoImage} alt="Airguard Logo" />
        <address>
          <p>
            <a
              href="https://www.google.com/maps?q=Adalet+Mah,+Megapol+Tower,+Anadolu+Cd.+No:+41,+35530+Bayraklı/İzmir"
              target="_blank"
              rel="noopener noreferrer"
            >
              Adalet Mah, Megapol Tower, Anadolu Cd. No: 41, 35530
              Bayraklı/İzmir
            </a>
          </p>
          <p>
            <a href="tel:+902329994950">Telefon: 0232 999 49 50</a>
          </p>
          <p>
            <a href="mailto:info@airguardtr.com">
              E-Posta: info@airguardtr.com
            </a>
          </p>
        </address>
      </div>

      <div className="footer-links">
        <div className="footer-column">
          <h4>Hızlı Bağlantılar</h4>
          <ul>
            <li>
              <a href="/">Anasayfa</a>
            </li>
            <li>
              <a href="/products">Ürünlerimiz</a>
            </li>
            <li>
              <a href="/calculator">Kazanç Hesaplama</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Kurumsal</h4>
          <ul>
            <li>
              <a href="/sss">Sıkça Sorulan Sorular</a>
            </li>
            <li>
              <a href="/pnp">Gizlilik Politikası</a>
            </li>
            <li>
              <a href="/contact">Bize Ulaşın</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Bizi Takip Edin</h4>
          <ul>
            <li>
              <a
                href="https://www.instagram.com/airguard.tr?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                target="_blank"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/p/Airguard-100063834960210/"
                target="_blank"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/airguardtr/"
                target="_blank"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>
          Patriot İthalat İhracat Otomotiv Sanayi Limited Şirketi © 2024 -
          Hakları Saklıdır.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
