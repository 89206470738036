import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logoImage from "../assets/logo-Desktop.png"; // Update this path
import "../styles/TopInfo.scss";

//icons
import facebook from "../assets/Facebook-icon.png";
import instagram from "../assets/Instagram-icon.png";
import linkedin from "../assets/Linkedin-icon.png";

const TopSection = ({ showContent }) => {
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  useEffect(() => {
    const footer = document.getElementById("footer"); // Make sure the footer has an ID
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsFooterVisible(true);
          } else {
            setIsFooterVisible(false);
          }
        });
      },
      {
        root: null, // observes within the viewport
        threshold: 0.2, // adjust this value as needed
      }
    );

    if (footer) {
      observer.observe(footer);
    }

    return () => {
      if (footer) observer.unobserve(footer);
    };
  }, []);

  return (
    <>
      {showContent && (
        <div className="top-section">
          <div className="top-contact">
            <a href="tel:+902329994950" className="contact-info">
              Bizi arayın: 0232 999 49 50
            </a>
            <a href="mailto:info@airguardtr.com" className="contact-info">
              E-Posta: info@airguardtr.com
            </a>
          </div>
          <div className="top-social">
            <span>Bizi Takip Edin: </span>
            <div className="icons">
              <a
                href="https://www.facebook.com/p/Airguard-100063834960210/"
                target="_blank"
              >
                <img className="icon" src={facebook} alt="facebook" />
              </a>
              <a
                href="https://www.instagram.com/airguard.tr?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                target="_blank"
              >
                <img className="icon" src={instagram} alt="instagram" />
              </a>
              <a
                href="https://www.linkedin.com/company/airguardtr/"
                target="_blank"
              >
                <img className="icon" src={linkedin} alt="linkedin" />
              </a>
            </div>
          </div>
        </div>
      )}
      <div className={`top-section__logo ${isFooterVisible ? "hidden" : ""}`}>
        <Link to="/">
          <img src={logoImage} alt="logo" width={150} />
        </Link>
      </div>
    </>
  );
};

export default TopSection;
