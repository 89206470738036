import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import "../styles/logos.scss";

import rightWings from "../assets/Lines-right.png";
import leftWings from "../assets/Lines-Left.png";

const Logos = ({ images }) => {
  return (
    <div className="partners">
      <div className="partners_title">
        <img src={leftWings} alt="text wings" />
        <h2>Bizi Tercih Edenler</h2>
        <img src={rightWings} alt="text wings" />
      </div>
      <Swiper
        className="custom-roll"
        modules={[Autoplay]}
        slidesPerView={5}
        spaceBetween={20}
        loop={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        }}
        speed={3000} // Control speed of sliding
        breakpoints={{
          320: { slidesPerView: 2 },
          568: { slidesPerView: 3 },
          1024: { slidesPerView: 5 },
          1440: { slidesPerView: 6 },
        }}
      >
        {images.map((logo, index) => (
          <SwiperSlide key={index} className="logo_slide">
            <img
              src={logo}
              alt={`Company logo ${index + 1}`}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Logos;
