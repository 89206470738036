import React from "react";
import Calculator from "../components/Calculator";

import "../styles/Calculate.scss";

const Calculate = () => {
  return (
    <div className="calculator_page">
      <Calculator />
    </div>
  );
};

export default Calculate;
