import React from "react";

import "../styles/PnP.scss";

const PnP = () => {
  return (
    <div className="privacy-policy">
      <h1>Airguard Gizlilik Politikası</h1>
      <p>
        <strong>Yürürlük Tarihi: 2023.11.15</strong>
      </p>

      <p>
        Patriot İthalat İhracat Otomotiv / Airguard (bundan sonra "biz" veya
        "Uygulama" olarak anılacaktır) gizliliğinize saygı duyar ve korur. Bu
        Gizlilik Politikası, size ve Uygulamanın kullanımında ilgili kişisel
        verileri nasıl topladığımız, kullandığımız ve açıkladığımız yanı sıra
        gizlilik haklarınız ve seçimleriniz hakkında sizi bilgilendirmek için
        tasarlanmıştır.
      </p>
      <p>
        Lütfen Uygulamayı kullanmadan önce bu Gizlilik Politikasını dikkatlice
        okuyun. Bu Gizlilik Politikasının herhangi bir bölümünü kabul
        etmiyorsanız Uygulamamızı kullanmayı bırakmalısınız.
      </p>

      <h2>1. Kişisel Verilerin Toplanması</h2>
      <p>Uygulama aşağıdaki türde kişisel verileri toplayabilir:</p>

      <h3>1.1 Cihaz Bilgileri:</h3>
      <p>
        Kullandığınız cihazla ilgili; cihaz modeli, işletim sistemi sürümü,
        benzersiz cihaz tanımlayıcıları (UDID) ve mobil cihaz bilgileri dahil
        olmak üzere bilgiler toplayabiliriz. Uygulama tanımlayıcıları (IDFA).
      </p>

      <h3>1.2 Giriş Bilgileri:</h3>
      <p>
        Uygulamanızdaki kullanımınız hakkında; IP adresiniz, tarayıcı türleri ve
        saatleri, uygulama çökme raporları ve diğer teşhis bilgileri gibi
        bilgileri toplarız ve bunları anonim olarak olarak değerlendiririz.
      </p>

      <h3>1.3 Kişisel Olmayan Tanımlayıcı Bilgiler:</h3>
      <p>
        Uygulamalarımızda ve hizmetlerimizde gezinme ve kullanım deneyimi
        geliştirmek amacıyla kimlik bilgileri bağlantılı olmadan topladığımız
        anonim veriler. Bu bilgiler bize kullanıcı davranışları hakkında bilgi
        verir.
      </p>

      <h2>2. Kişisel Verilerin Kullanımı</h2>
      <p>
        Uygulama topladığı kişisel verileri aşağıdaki amaçlar için kullanabilir:
      </p>

      <h3>
        2.1 Size kişiselleştirilmiş içerik, özellikler ve reklamlar sağlamak.
      </h3>

      <h3>
        2.2 Uygulamanın kullanımına yanıt olarak tercihleri ve ilgi alanlarını
        analiz etmek ve değerlendirmek.
      </h3>

      <h3>
        2.3 İsteklerinizi, geri bildirimlerinizi ve desteğinizi işleme koymak.
      </h3>

      <h3>
        2.4 Uygulamanızda ilgili teknik sorunların veya güvenlik sorunlarının
        tespit edilmesi, önlenmesi ve ele alınması.
      </h3>

      <h3>
        2.5 Yürürlükteki kanunlar, düzenlemeler ve diğer hukuki talepler
        doğrultusunda hareket etmek.
      </h3>

      <h2>3. Kişisel Verilerin Açıklanması</h2>
      <p>Uygulama, aşağıdaki durumlarda kişisel bilgilerinizi paylaşabilir:</p>

      <h3>3.1 Yasal talepler:</h3>
      <p>
        Mahkeme celbi, kanun veya yetkili makam tarafından gerekli görüldüğünde
        Üçüncü şahıslara açıklanabilir.
      </p>

      <h3>3.2 Hizmet sağlayıcılar:</h3>
      <p>
        Üçüncü taraf hizmet sağlayıcılarla kişisel verilerinizi yalnızca
        işlevlerini yerine getirmeleri için paylaşabiliriz.
      </p>

      <h3>3.3 Yasal Gereklilikler:</h3>
      <p>
        Yasal süreçlere uygunluğumuzu sağlamak veya haklarımızı savunmak
        amacıyla kişisel verilerinizi açıklayabiliriz.
      </p>

      <h2>4. Veri Güvenliği</h2>
      <p>
        Kişisel verilerinizi güvenliğini korumak için makul güvenlik önlemleri
        alıyoruz. Ancak internet üzerinden verilerin %100 güvenliğini garanti
        edemeyiz.
      </p>

      <h2>5. Gizlilik Seçimleri</h2>
      <p>
        Kişisel verilerinizin toplanmasını ve kullanılmasını sınırlandırma
        hakkına sahipsiniz. İlgili uygulama ayarlarını kullanabilirsiniz.
      </p>

      <h2>6. Yaş Sınırlamaları</h2>
      <p>
        Uygulama 18 yaş altındaki çocuklar için uygun değildir. 18 yaşından
        küçükler ebeveynlerinin izni olmadan kişisel verilerini paylaşmamalıdır.
      </p>

      <h2>7. Bu Gizlilik Politikasındaki Değişiklikler</h2>
      <p>
        Bu Gizlilik Politikasını güncelleyebiliriz. Herhangi bir değişiklik
        durumunda size bildirimde bulunacağız.
      </p>

      <h2>8. Bize Ulaşın</h2>
      <p>
        Gizlilik Politikamızla ilgili herhangi bir sorunuz olursa, lütfen
        bizimle iletişime geçin:
      </p>
      <p>
        <strong>Telif hakkı © 2024 kamuya aydınlatma yükümlülüğü</strong>
      </p>
    </div>
  );
};

export default PnP;
