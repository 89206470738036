import React from "react";
import "../styles/ReviewCard.scss";

import quoteImg from "../assets/quote.png";

const ReviewCard = ({ review, className }) => {
  return (
    <div className={`review_card ${className}`}>
      <div className="review_card_name">
        <img src={quoteImg} alt="quote" />
        <h3>{review.name}</h3>
      </div>
      <h4 className="review_card_position">{review.position}</h4>
      <p className="review_card_text">{review.text}</p>
    </div>
  );
};

export default ReviewCard;
