import React from "react";
import FAQ from "../components/FAQ";

import "../styles/Sss.scss";

const faqData = [
  {
    question: "Airguard sistemi hangi ürünlerden oluşuyor?",
    answer:
      "Airguard , lastik basınç ve sıcaklık görüntüleme monitörü, harici ve dahili sensör, sinyal güçlendirici ve opsiyonel olarak tedarik edilen online raporlama cihazından oluşmaktadır.",
  },
  {
    question: "Airguard nasıl çalışır?",
    answer:
      "Airguard ,sensörlerden alınan lastik basınç ve sıcaklık bilgilerini kablosuz bir şekilde monitöre göndererek,anlık bilgi akışı sağlar. İsteğe göre belirlenen basınç ve sıcaklık baremlerinin dışında bir bilgi gelmesi durumunda monitörden işitsel ve görsel alarm vererek operatörü uyarmaktadır.",
  },
  {
    question: "Airguard montajı ne kadar sürüyor?",
    answer:
      "Dahili ve harici sensör uygulamalarında süre değişmektedir. Çalınmaya karşı özel kilitli harici sensör, subap kapağı yerine takıldığı için bir araca montajı ortalama 45 dk , dahili sensör takılmasında lastik topuk kısmı janttan düşürülerek subap takılan yere takıldığı için bir araca ortalama iki saatlik sürede takılabilmektedir.",
  },
  {
    question: "Airguard uzaktan bilgilendirme (online) sistemi mevcut mudur?",
    answer:
      "Airguard uzaktan bilgilendirme sistemi , websitesi üzerinden girilen kullanıcı adı ve şifre bilgileriyle ,aracın lokasyonu ve hızı gibi temel bilgiler ile birlikte,tüm araçlarınızın lastikleri için tek tek anlık basınç ve sıcaklık bilgilerini görüntüleyebileceğiniz bir platformdur. Bununla birlikte tarih aralığı seçerek araçlarınızın plaka bazlı lastik alarm geçmişlerini görüntüleyerek rapor oluşturabilirsiniz.",
  },
  {
    question: "Airguard sisteminin ömrü ne kadardır?",
    answer:
      "Sistem bileşenlerinden sensörler güç kaynağı olarak pil kullanmaktadır. Dahili sensörler pili değişemeyen , harici sensörler pili değişebilen özelliktedir. Sensörlerin pil ömürleri ortalama 450.000 – 500.000 km’dir. Pili değişebilen harici sensörlerin pilleri kolayca bulunan küçük pillerdir, kullanıcılar isteğe göre pil değişimlerini kendileri ya da servis hizmeti alarak değiştirebilirler.",
  },
  {
    question: "Airguard sistemi yazılım ve donanım garantisi var mıdır?",
    answer: "Sistemin yazılımsal ve donanımsal garantisi iki yıldır.",
  },
];

const Sss = () => {
  return (
    <div className="sss">
      <FAQ faqData={faqData} />
    </div>
  );
};

export default Sss;
