import React from "react";
import ContactForm from "../components/ContactForm";

import "../styles/ContactUs.scss";

import emailIcon from "../assets/Email.png";
import phoneIcon from "../assets/Phone.png";
import addressIcon from "../assets/Location.png";

const ContactUs = () => {
  return (
    <div className="contact-us">
      <div className="contact-us_container">
        <ContactForm />
        <div className="contact-us_detail">
          <h1>Bize Ulaşın</h1>
          <p>
            Size en iyi şekilde yardımcı olmak için buradayız. Hemen bize
            ulaşın!
          </p>
          <div className="contact-us_creds">
            <div className="creds_group">
              <img src={phoneIcon} alt="" />
              <p>
                <a href="tel:+902329994950">Telefon: 0232 999 49 50</a>
              </p>
            </div>
            <div className="creds_group">
              <img src={emailIcon} alt="" />
              <p>
                <a href="mailto:info@airguardtr.com">
                  E-Posta: info@airguardtr.com
                </a>
              </p>
            </div>
            <div className="creds_group">
              <img src={addressIcon} alt="" />
              <p>
                <a
                  href="https://www.google.com/maps?q=Adalet+Mah,+Megapol+Tower,+Anadolu+Cd.+No:+41,+35530+Bayraklı/İzmir"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Adalet Mah, Megapol Tower, Anadolu Cd. No: 41, 35530
                  Bayraklı/İzmir
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
