import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

//Pages
import Home from "./pages/HomePage";

//Components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import TopInfo from "./components/TopInfo";
import GoToTop from "./components/GoToTop";

import Product from "./pages/Product";
import AboutUs from "./pages/AboutUs";
import Products from "./pages/Products";
import PnP from "./pages/PnP";
import ContactUs from "./pages/ContactUs";
import Sss from "./pages/Sss";
import Calculate from "./pages/Calculate";
import CookieConsent from "./CookieConsent";

function App() {
  const MainLayout = () => {
    return (
      <div className="app">
        <TopInfo showContent={false} />
        <Navbar />
        <ScrollToTop /> {/* Add ScrollToTop here */}
        <Outlet />
        <GoToTop />
        <CookieConsent />
        <Footer />
      </div>
    );
  };

  const HomeLayout = () => {
    return (
      <div className="app">
        <TopInfo showContent={true} />
        <Navbar />
        <ScrollToTop /> {/* Add ScrollToTop here */}
        <Outlet />
        <GoToTop />
        <CookieConsent />
        <Footer />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "calculator",
          element: <Calculate />,
        },
        {
          path: "about",
          element: <AboutUs />,
        },
        {
          path: "/products",
          element: <Products />,
        },
        {
          path: "/product/:name",
          element: <Product />,
        },
        {
          path: "/pnp",
          element: <PnP />,
        },
        {
          path: "/contact",
          element: <ContactUs />,
        },
        {
          path: "/sss",
          element: <Sss />,
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />{" "}
    </>
  );
}

export default App;
