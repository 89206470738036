import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

import "../styles/YtCarousel.scss";

const YtCarousel = ({ videos }) => {
  return (
    <div className="ytCarousel">
      <div className="ytCarousel_container">
        <h1>Airguard Kullananlar</h1>
        <Swiper
          modules={[Pagination, Autoplay]} // Add Mousewheel for scroll interaction
          allowSlidePrev={true} // Allow slide to previous
          loop={true} // Infinite loop
          mousewheel={true} // Allow slide by mouse wheel
          grabCursor={true} // Allow dragging by mouse        loop={true}
          pagination={{ clickable: true }}
          autoplay={{ delay: 6000, disableOnInteraction: false }}
          shortSwipes={true} // Allow short swipe
          longSwipes={true} // Allow long swipe
          slidesOffsetAfter={200}
          spaceBetween={0}
          slidesPerView={1}
          breakpoints={{
            320: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
        >
          {videos.map((video, index) => (
            <SwiperSlide key={index} className="slideIn">
              <div className="video-container">
                <iframe
                  src={video}
                  title={`YouTube video ${index + 1}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default YtCarousel;
