import React, { useState } from "react";
import newRequest from "../utils/newRequest";
import { useLocation } from "react-router-dom";
import "../styles/ContactForm.scss";
import Popup from "./Popup";

const ContactForm = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";

  const [formData, setFormData] = useState({
    ad: "",
    email: "",
    phone: "",
    message: "",
  });

  const [popup, setPopup] = useState({ show: false, message: "", type: "" });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await newRequest.post("/contact", formData);
      const results = response.data;
      console.log(results);
      setPopup({
        show: true,
        message: "Mesajınız başarıyla gönderildi!",
        type: "success",
      });
    } catch (error) {
      console.error("Bir hata oluştu", error);
      console.error("Hata mesajı", error.response.data);
      const errorMessages = Object.values(error.response.data.errors).join(
        ", "
      );
      setPopup({
        show: true,
        message: errorMessages,
        type: "error",
      });
    } finally {
      setFormData({ ad: "", email: "", phone: "", message: "" });
      setTimeout(() => setPopup({ show: false, message: "", type: "" }), 3000); // Hide after 3 seconds
    }
  };

  return (
    <div className={`${isHome ? "contact_home" : "contact_form"}`}>
      <h3>Size Ulaşalım</h3>
      <form onSubmit={handleSubmit}>
        <div className="form_group">
          <div className="input_wrapper"></div>

          <input
            type="text"
            name="ad"
            placeholder="Ad Soyad"
            className="form_control"
            value={formData.ad}
            onChange={handleChange}
            pattern="[A-Za-z\s]{2,}"
            title="Lütfen en az 2 harf girin."
          />
          <i className="input_icon fa fa-user"></i>
        </div>

        <div className="form_group">
          <input
            type="email"
            name="email"
            placeholder="E-Posta"
            className="form_control"
            value={formData.email}
            onChange={handleChange}
            title="Lütfen geçerli bir e-posta adresi girin."
          />
          <input
            type="tel"
            name="phone"
            placeholder="Telefon"
            className="form_control"
            value={formData.phone}
            onChange={handleChange}
            pattern="^\+?[0-9\s-]{10,15}$"
            title="Lütfen geçerli bir telefon numarası girin."
          />
        </div>
        <div className="form_group">
          <textarea
            name="message"
            placeholder="Mesajınız"
            className="form_control"
            value={formData.message}
            onChange={handleChange}
            title="Mesaj en az 10 karakter uzunluğunda olmalıdır."
          ></textarea>
        </div>
        <button type="submit" className="btn submit_btn">
          Mesajınızı Gönderin!
        </button>
      </form>
      {popup.show && <Popup message={popup.message} type={popup.type} />}
    </div>
  );
};

export default ContactForm;
